import './App.css';
import Map from "./map/Map";

function App() {
    return (
        <div className={'container'}>
            <div className={"left"}>
                <div className={"left-top bg-marmeladenglaeser"}>
                    <h1>Hammer Marmelade</h1>
                </div>
                <div className={"left-bottom bg-bergbau"}>
                    <section>
                        <h2>
                            Leckereien aus dem heimischen Garten
                        </h2>
                        <p>
                            Warum in die Ferne schweifen, wenn das Glück vor der Tür liegt?
                            Marmelade, handgemacht aus Früchten die in Bochums Gärten wachsen,
                            eingekocht im schmucken Stadtteil Hamme.
                            Von Hammer-Menschen, für hammer Leute!
                            Wird nur verschenkt, nicht verkauft.
                        </p>
                    </section>
                </div>
            </div>
            <div className={"right"}>
                <div className={"right-top"}>
                    <Map/>
                </div>
            </div>
        </div>
    );
}

export default App;
